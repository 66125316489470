import { createStore } from "vuex";

const store = createStore({
    state() {
        return {
        };
    },
    mutations: {
    },
    actions: {
        // this.$store.dispatch("login", data);
    },
    getters: {
    }
});

export default store;