import { createRouter, createWebHistory } from "vue-router";
import { ElMessage } from "element-plus";

import { getSession, setSession } from '@/utils/session';
import sessionEnum from '@/utils/session';
import { HOME_INDEX } from '@/utils/envConfig'

const configInitIndex = {
    xun: {
        index: 'xunIndex.vue'
    },
    common: {
        index: 'index.vue'
    },
    ai: {
        index: 'aiIndex.vue'
    }
}

const envIndex = HOME_INDEX || 'common'
const pathIndex = configInitIndex[envIndex] || configInitIndex['common'];

const routesInit = [
    { name: 'noNull', path: '/', redirect: '/login', children: [] },
    { name: "login", path: '/login', component:loadComponent(`${pathIndex.index}`), children: [] },
    { name: "home", path: '/home', component: () => import('@/views/home.vue'), children: [], meta: { requiresAuth: true } },
    { name: "frontHome", path: '/frontHome', component: () => import('@/views/frontHome.vue'), meta: { requiresAuth: true }, children: [], },
    { name: '404', path: '/404', component: () => import('@/components/404.vue') },
    { name: 'catchAll', path: '/:catchAll(.+)', redirect: '/404' },
]

var router = createRouter({
    history: createWebHistory(),
    routes: routesInit,
    hasLoadedDynamicRoutes: false
});


function checkAccess(to, next) {
    const xunsign = getSession(sessionEnum.XUN_SIGN);
    if (!xunsign || !xunsign.trim() || xunsign.toLowerCase() === 'null') {
        ElMessage({ message: 'token失效', type: 'error' })
        return next('/');
    }
    const resWitch = getSession(sessionEnum.CURRENT_ROLE).withRole
    if (to.path === '/login' || to.path === '/' || to.path === '/' + resWitch) {
        return next();
    }
    const allRouterData = getSession(sessionEnum.ROUTER_ENTITY);
    if (!allRouterData || allRouterData.length === 0) {
        return next('/404');
    }
    return next();
}


function handleGuard(to, from, next) {
    const matchedRouter = router.getRoutes().find(r => r.path === to.path);
    if (matchedRouter) {
        if (matchedRouter.meta.requiresAuth) {
            return checkAccess(to, next);
        } else {
            return next();
        }
    } else {
        //获取全部的路由
        const allRoutesData = getSession(sessionEnum.ROUTER_ENTITY);
        if (!allRoutesData || allRoutesData.length === 0) {
            return next('/404');
        }
        const saveRoute = allRoutesData.find(r => r.path === to.path);
        if (saveRoute) {
            if (saveRoute.path !== to.path) {
                return checkAccess(to, next);
            } else {
                return next(false);
            }
        } else {
            return next('/404');
        }
    }
}

router.beforeEach(handleGuard);

function loadComponent(component) {
    return () => import(`@/views/${component}`).catch((e) => {
        console.log('error', e);
        return import('@/components/404.vue');
    });
}

export function deleteTopRoute(parent) {
    if (parent == 'frontHome') {
        router.removeRoute('home');
    } else if (parent == 'home') {
        router.removeRoute('frontHome');
    }
    const current = getSession(sessionEnum.CURRENT_ROLE);
    current.withRole = parent;
    setSession(sessionEnum.CURRENT_ROLE, current)
}

export function addRoutesRecursively(routes, parent = null) {
    routes.forEach(vo => {
        vo.children = vo.children || [];
        vo.attachList = vo.attachList || [];
        vo.isLink = vo.isLink || 2
        vo.menuType = vo.menuType || 2

        if (vo.component != null && vo.menuType === '1' && vo.isLink === 2) {
            const metaAuth = true
            const routeCofig = {
                path: vo.path ? (vo.path.startsWith('/') ? vo.path : '/' + vo.path) : '/',
                name: (vo.path || '').startsWith('/') ? (vo.path || '').slice(1) : vo.path || '',
                component: loadComponent(vo.component),
                meta: { requiresAuth: metaAuth },
                children: vo.children,
                isShow: vo.isShow === undefined ? true : vo.isShow,
                seniorStatus: vo.seniorStatus === undefined ? '1' : vo.seniorStatus
            }

            if (parent === 'frontHome') {
                const exit = routesInit[3].children.some(router => router.name === routeCofig.name);
                if (!exit) {
                    routesInit[3].children.unshift(routeCofig);
                }
                router.addRoute('frontHome', routeCofig);
            } else if (parent === 'home') {
                const exit = routesInit[2].children.some(router => router.name === routeCofig.name);
                if (!exit) {
                    routesInit[2].children.unshift(routeCofig);
                }
                router.addRoute('home', routeCofig);
            } else {
                router.addRoute(routeCofig);
            }

            if (vo.attachList && vo.attachList.length > 0) {
                vo.attachList.forEach(attach => {
                    const attachConfig = {
                        path: attach.path ? (attach.path.startsWith('/') ? attach.path : '/' + attach.path) : '/',
                        name: (attach.path || '').startsWith('/') ? (attach.path || '').slice(1) : attach.path || '',
                        component: loadComponent(attach.component),
                        meta: { requiresAuth: metaAuth },
                        children: attach.children,
                        isShow: attach.isShow === undefined ? true : attach.isShow,
                        seniorStatus: attach.seniorStatus === undefined ? '1' : attach.seniorStatus
                    }
                    if (parent === 'frontHome') {
                        const exit = routesInit[3].children.some(router => router.name === attachConfig.name);
                        if (!exit) {
                            routesInit[3].children.unshift(attachConfig);
                        }
                        router.addRoute('frontHome', attachConfig);
                    } else if (parent === 'home') {
                        const exit = routesInit[2].children.some(router => router.name === attachConfig.name);
                        if (!exit) {
                            routesInit[2].children.unshift(attachConfig);
                        }
                        router.addRoute('home', attachConfig);
                    } else {
                        router.addRoute(attachConfig);
                    }
                })
            }

        }


        if (vo.children && vo.children.length) {
            addRoutesRecursively(vo.children, parent);
        }
    });

    setSession(sessionEnum.ROUTER_ENTITY, routes);
    // window.sessionStorage.setItem('routesEntity', JSON.stringify(routes));
}

export function reseutRouter() {
    window.location.reload();
}
export function loadRoutesSessionStrorag() {
    const current = getSession(sessionEnum.CURRENT_ROLE);
    const routeWitch = current.withRole;

    if (routeWitch) {
        deleteTopRoute(routeWitch)
    }

    const routesEntity = getSession(sessionEnum.ROUTER_ENTITY)
    if (routesEntity) {
        addRoutesRecursively(routesEntity, routeWitch)
    }


}

export default router