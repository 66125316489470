const sessionEnum = Object.freeze({
    XUN_SIGN: 'xunsign',
    USER_INFO: 'userInfo',
    ROUTER_ENTITY: 'routesEntity',
    CURRENT_ROLE: 'currentRole',
    SYSTEM_ROLE: 'systemRole'
})

function checkSession(key) {
    if (!Object.values(sessionEnum).includes(key)) {
        throw new Error(`session key ${key} is not exist`)
    }
}

//切换角色只要替换 ROUTER_ENTITY 跟 CURRENT_ROLE

export function clearSession() {
    window.sessionStorage.clear();
}


export function getSession(key) {
    checkSession(key)
    switch (key) {
        case sessionEnum.XUN_SIGN:
            return window.sessionStorage.getItem(key)
        case sessionEnum.USER_INFO:
            const userInfo = window.sessionStorage.getItem(key);
            if (userInfo && userInfo !== "undefined" && userInfo !== "null") {
                return JSON.parse(userInfo)
            }
            return {}
        case sessionEnum.ROUTER_ENTITY:
            return JSON.parse(window.sessionStorage.getItem(key))
        case sessionEnum.SYSTEM_ROLE:
            const roleItem = window.sessionStorage.getItem(key);
            if (roleItem && roleItem !== "undefined" && roleItem !== "null") {
                return JSON.parse(roleItem)
            }
            return {
                systemList:[],
                roleList:[],
                systemRole:[]
            }
        case sessionEnum.CURRENT_ROLE:
            const item = window.sessionStorage.getItem(key);
            if (item && item !== "undefined" && item !== "null") {
                return JSON.parse(item)
            }
            return {
                withRole: null,
                loadingSwitch: false,
                systemName: null,
                systemCode: null,
                templateVersion: null,
                roleName: null,
                roleCode: null,
                roleUniqueId: null,
                uniqueId: null,
                indexPath:null
            }


        default:

            throw new Error(`session key ${key} is not exist`);
    }
}

export function setSession(key, value) {
    checkSession(key)
    switch (key) {
        case sessionEnum.XUN_SIGN:
            window.sessionStorage.setItem(key, value)
            return;
        case sessionEnum.USER_INFO:
        case sessionEnum.ROUTER_ENTITY:
        case sessionEnum.CURRENT_ROLE:
        case sessionEnum.SYSTEM_ROLE:    
            window.sessionStorage.setItem(key, JSON.stringify(value))
            return;
        default:
            throw new Error(`session key ${key} is not exist`);
    }
}

export function removeSession(key) {
    checkSession(key)
    window.sessionStorage.removeItem(key)
}



//获取默认角色
export function findDefaultRole(data) {
    let firstRole = null;
    const checkNode = (node) => {
        const { isDefault, type } = node;
        if ((type === 2 || type === null) && isDefault === 1) {
            return node;
        }
        if ((type === 2 || type === null) && !firstRole) {
            firstRole = node;
        }
        return null;
    }

    for (const item of data) {
        const result = checkNode(item);
        if (result) {
            return result;
        }
        if (Array.isArray(item.roleList)) {
            for (const node of item.roleList) {
                const childResult = checkNode(node);
                if (childResult) {
                    return childResult;
                }
            }
        }
    }
    return firstRole;
}

export default sessionEnum;