export const messages={
    zh:{
        role:{
            name:'角色名称',
        },
        user:{
            name:'用户名称'
        }
    },
    en:{
        role:{
            name:'RoleName',
        }
    },
    jp:{
        role:{
            name:'役割名',
        }
    }
}